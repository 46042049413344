<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Paquetes de timbres'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave}}</div></td>
                                <td><div>{{item.nombre}}</div></td>
                                <td><div>{{item.timbres}}</div></td>
                                <td><div>{{formatNum(item.precio)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" class="d-flex justify-center my-10" v-if="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-if="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Clave" 
                                                class="pa-0 ma-0" 
                                                v-model="paquete.clave" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Nombre" 
                                                class="pa-0 ma-0" 
                                                v-model="paquete.nombre" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                  
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Timbres" rules="required">
                                            <v-text-field
                                                outlined
                                                label="Timbres"
                                                v-model="paquete.timbres"
                                                :error-messages="errors"
                                                v-mask="'#######'"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="6" class="py-0 my-0">
                                        <VuetifyMoneyValidate
                                            rules="required"
                                            outlined
                                            label="Precio"
                                            class="pa-0 ma-0"
                                            prefix="$"
                                            v-model="paquete.precio"
                                            v-bind:options="options"
                                            ref="precioPaquete"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="IVA" rules="required">
                                            <v-text-field
                                                outlined
                                                label="IVA"
                                                v-model="paquete.iva_porcentaje"
                                                :error-messages="errors"
                                                
                                                suffix="%"
                                                min=0
                                                max=100
                                                v-on:keypress="presicionNumeroPorcentaje($event,paquete.iva_porcentaje)"
                                                oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                      <v-col cols="12" xs="12" sm="12" md="12" class="py-0 my-0">
                                        <ValidationProvider v-slot="{ errors }" name="Descripcion" rules="required">
                                            <v-textarea
                                                outlined
                                                label="Descripción" 
                                                class="pa-0 ma-0" 
                                                v-model="paquete.descripcion" 
                                                :error-messages="errors" 
                                                required
                                                rows="2"
                                            >
                                            </v-textarea>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col v-if="accion === 'add'" cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Imagen" rules="required">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="paquete.archivo"
                                                required 
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-else cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                        <v-file-input
                                            outlined
                                            label="Seleccionar el documento"
                                            v-model="paquete.archivo"
                                            prepend-icon=""
                                            append-icon="attach_file"
                                            id="fileDocument"
                                            @click:append="open"
                                        >
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import apiConfigurarTimbres from "@/api/nominas/configurarTimbres";
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';

export default {
    components: {
        'data-table': Datatable,
        VuetifyMoneyValidate
    },
    data() {
        return {
            url         : "paquetes-timbres/find",
            columns     : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: false
                },
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label: 'Numero de timbres',
                    name: 'timbres',
                    filterable: false
                },
                {
                    label: 'Precio',
                    name: 'precio',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc"
            },
            paquete        : {
                id              : null,
                clave           : null,
                nombre          : null,
                descripcion     : null,
                timbres         : null,
                precio          : null,
                iva_porcentaje  : null,
                archivo         : null,

            },
            datosLogin      : null,
            rol             : null,
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : "",
            options: {
                length: 11,
                locale: "es-MX",
                precision: 2,
                prefix: "$",
                suffix: "",
            }, 
            archivo : null,

        }
    },
    methods: {
        open(){
            document.getElementById("fileDocument").click();
        },
        presicionNumeroPorcentaje(event, valor){
            if (/\.\d{2}/.test(valor)) 
                return event.preventDefault();
        },
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;

            if(accion == "add") {
                this.tituloModal    = "Nuevo paquete de timbre";
                this.imagen         = "/static/modal/catalogoSATcreate.svg";
            } else {
                this.tituloModal            = "Actualizar paquete de timbre";
                this.imagen                 = "/static/modal/catalogoSATupdate.svg";
                this.paquete.id             = data.id;
                this.paquete.clave          = data.clave;
                this.paquete.nombre         = data.nombre;
                this.paquete.descripcion    = data.descripcion;
                this.paquete.timbres        = data.timbres;
                this.paquete.precio         = data.precio;
                this.paquete.iva_porcentaje = data.iva_porcentaje;

            }

            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.paquete.id             = null;
            this.paquete.clave          = null;
            this.paquete.nombre         = null;
            this.paquete.descripcion    = null;
            this.paquete.timbres        = null;
            this.paquete.precio         = null;
            this.paquete.archivo        = null;
            this.paquete.iva_porcentaje = null;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }

            let formData = new FormData();
            formData.append('clave', this.paquete.clave);
            formData.append('nombre', this.paquete.nombre);
            formData.append('descripcion', this.paquete.descripcion);
            formData.append('timbres',this.paquete.timbres);
            formData.append('precio',this.paquete.precio);
            formData.append('iva_porcentaje',this.paquete.iva_porcentaje);
            
            if(this.paquete.archivo != null) {
                formData.append('archivo',this.paquete.archivo);
            }

            if(this.accion === "add") {
                apiConfigurarTimbres.storePaquetesTimbres(formData).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "El paquete de timbres se guardó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                formData.append('id', this.paquete.id);
                apiConfigurarTimbres.updatePaquetesTimbres(formData).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "El paquete de timbres se actualizó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar este paquete de timbres?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiConfigurarTimbres.deletePaquetesTimbres(data.id).then(response => {
                        this.$refs.tabla.getData();
                        Notify.Success("Paquete de timbres eliminado", "El paquete de timbres ha sido eliminado satisfactoriamente");
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },  
        redireccionar(){
            this.$router.push('/dashboard');
        }
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
    }
}
</script>

<style scoped>
    
</style>
